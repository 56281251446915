<template>
  <div style="max-width: 100%; width: 100%; margin-right: 1rem">
    <p><strong>Finalizar turno</strong></p>
    <div v-show="!showTypify">
      <v-btn
        v-if="config && config.finishBtnEnabled"
        color="error"
        block
        style="margin: 1rem"
        @click="() => handleFinishCall(false)"
        :disabled="
          !!!callData && updateStateValue !== 'Conectado - En atención'
        "
        >Finalizar</v-btn
      >
      <v-btn
        color="error"
        :disabled="
          !!!callData && updateStateValue !== 'Conectado - En atención'
        "
        block
        @click="() => handleFinishCall(true)"
        style="margin: 1rem"
        >No se presentó</v-btn
      >

      <v-btn
        color="error"
        v-if="config && config.absentBtnEnabled"
        :disabled="
          !!!callData && updateStateValue !== 'Conectado - En atención'
        "
        block
        @click="() => handleFinishCall('absent')"
        style="margin: 1rem"
        >Ausentar turno</v-btn
      >

      <v-btn
        color="error"
        v-if="
          loggedData.worker &&
          loggedData.worker.canDerivate &&
          config.derivateBtnEnabled
        "
        :disabled="
          !!!callData && updateStateValue !== 'Conectado - En atención'
        "
        block
        @click="() => handleDerive(true)"
        style="margin: 1rem"
        >Derivar</v-btn
      >
    </div>

    <!-- Typify -->
    <div
      v-show="showTypify && options.length > 0 && !showTypifyOptions"
      style="max-width: 100%; width: 100%; margin-right: 1rem"
    >
      <v-btn
        color="blue"
        block
        style="margin: 1rem; color: white; display: block; white-space: normal"
        @click="() => handleTypifyFinishCall(false)"
        >Tipificar y Terminar</v-btn
      >
      <v-btn
        color="blue"
        block
        @click="() => handleTypifyContinueCall(true)"
        style="margin: 1rem; color: white; display: block; white-space: normal"
        >Tipificar y Seguir</v-btn
      >
      <v-btn color="error" block @click="closeTypify" style="margin: 1rem"
        >Cancelar</v-btn
      >
    </div>
    <!-- Typification Options -->
    <v-dialog persistent v-model="showSearchFinish" max-width="70%" class="p-0">
      <div
        v-show="showTypify && options.length > 0 && showTypifyOptions"
        style="
          max-width: 100%;
          width: 100%;
          margin-right: 1rem;
          overflow-x: hidden;
        "
      >
        <div class="d-flex align-baseline" style="background-color: white">
          <v-row>
            <v-col cols="12" md="12" class="ml-2">
              <span class="py-0" style="max-width: 50px"
                >Selecciona una opción</span
              >
            </v-col>

            <v-col cols="12" md="12" class="py-0">
              <v-list nav dense class="pt-0">
                <v-subheader>
                  <v-text-field
                    label="Buscar..."
                    v-model="searchOption"
                    type="search"
                    clearable
                    hide-details="auto"
                  ></v-text-field>
                </v-subheader>
                <v-list three-line style="margin: 30px" class="scrollabel">
                  <div
                    class="Grid"
                    v-if="
                      options.filter((o) =>
                        searchOption === null
                          ? o.name
                          : o.name
                              .toLowerCase()
                              .includes(searchOption.toLowerCase())
                      ).length
                    "
                  >
                    <v-col
                      v-for="(item, i) in organizer"
                      :key="i"
                      @click="() => handleTypify(item)"
                      :cols="3"
                      style="max-width: 260px; padding: 5px"
                    >
                      <v-card
                        class="mx-auto btndkdk"
                        outlined
                        style="
                          width: 100%;
                          cursor: pointer;
                          text-align: center;
                          height: 50px;
                        "
                      >
                        <v-list-item
                          three-line
                          style="
                            width: 100%;
                            min-width: 205px;
                            height: 50px;
                            min-height: 50px;
                            height: 50px;
                          "
                        >
                          <v-list-item-content
                            style="
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              height: 50px;
                            "
                          >
                            <p style="margin: 0" class="dkpp">
                              {{ item.name }}
                            </p>
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </v-col>
                  </div>
                  <p v-else>No hay resultados</p>
                </v-list>
              </v-list>
            </v-col>

            <v-col class="p-0" style="background-color: white; padding: 0">
              <v-btn
                style="padding: 0"
                class="p-0"
                block
                @click="closeTipifyOptions"
                >Cancelar</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </div>
    </v-dialog>
    <derive-modal ref="deriveModal" @finishCall="finishCall"></derive-modal>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";

export default {
  name: "Typify",
  components: {
    DeriveModal: () => import("./DeriveModal"),
  },
  props: {
    callData: {
      required: true,
    },
    pollingData: {
      required: true,
    },
    loggedData: {
      required: true,
    },
  },
  data() {
    return {
      showTypify: false,
      showTypifyOptions: false,
      typifyFinishCall: false,
      options: [],
      option: null,
      searchOption: "",
      showSearchFinish: false,
    };
  },
  methods: {
    ...mapActions([
      "setTypify",
      "toTypify",
      "revokeTurnAction",
      "resetCallData",
    ]),
    handleFinishCall(event) {
      if (!event) {
        this.options = [];
        if (this.loggedData.worker?.actualTurn) {
          if (
            this.loggedData.worker.actualTurn.queue
            // &&
            // this.loggedData.worker.configs.typifyServiceEnabled
          ) {
            if (this.loggedData.worker.actualTurn.queue.services) {
              this.showTypify = true;
              this.options =
                this.loggedData.worker.actualTurn.queue.services || [];
            }
          }
        } else {
          if (this.callData?.queue) {
            this.showTypify = true;
            this.options = this.callData.queue?.services || [];
          }
        }
        if (this.options.length > 0) return;
      }
      return this.$emit("finishCall", event);
    },
    closeTipifyOptions() {
      this.showTypifyOptions = !this.showTypifyOptions;
      this.showSearchFinish = false;
    },
    handleTypifyFinishCall() {
      this.showTypifyOptions = true;
      this.typifyFinishCall = true;
    },
    handleTypifyContinueCall() {
      this.showTypifyOptions = true;
      this.typifyFinishCall = false;
    },
    closeTypify() {
      this.showTypify = false;
      this.options = [];
      this.showTypifyOptions = false;
      this.option = null;
    },
    filterOptions() {
      return this.options.filter((o) => o.name.search(this.searchOption));
    },
    handleDerive() {
      this.$refs.deriveModal.open();
    },
    finishCall() {
      this.resetCallData();
      this.$emit("reset");
    },
    handleRevokeTurn() {
      this.revokeTurnAction();
    },
    handleTypify(item) {
      this.showSearchFinish = false;

      // set turns
      this.setAttendeds();
      // ---
      if (item) {
        if (this.typifyFinishCall) {
          this.$emit("finishCall", false, item.id);
        } else {
          this.toTypify(item.id);
        }
        this.closeTypify();
      }
    },
    setAttendeds() {
      let today = moment().format("YYYY-MM-DD");
      if (localStorage.getItem("turns_attended" + today)) {
        this.turns_attended = JSON.parse(
          localStorage.getItem("turns_attended" + today)
        );
        if (
          this.turns_attended[today] &&
          this.turns_attended[today].indexOf(this.callData.code) < 0
        ) {
          this.turns_attended[today].push(this.callData.code);
          localStorage.setItem(
            "turns_attended" + today,
            JSON.stringify(this.turns_attended)
          );
        }
      } else {
        this.turns_attended[today] = [];
        localStorage.setItem(
          "turns_attended" + today,
          JSON.stringify(this.turns_attended)
        );
      }
    },
  },
  watch: {
    showTypifyOptions(val) {
      if (val === true) {
        this.showSearchFinish = true;
      }
    },
  },
  computed: {
    ...mapState({
      typifyOption: (state) => state.typifySilver,
      config: (state) => state.loggedUserSilver.loggedUserData.worker?.configs,
      updateStateValue: (state) => state.loggedUserSilver.updateStateValue,
    }),
    organizer() {
      return this.options
        .filter((o) =>
          this.searchOption === null
            ? o.name.toLowerCase()
            : o.name.toLowerCase().includes(this.searchOption.toLowerCase())
        )
        .slice()
        .sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : a.name.toLowerCase() === b.name.toLowerCase()
            ? 0
            : -1
        );
    },
  },
};
</script>
<style>
.active {
  min-width: 150px;
}
.dkpp {
  /* text-overflow: ellipsis; */
  max-width: 175px;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
}
.btndkdk {
  transition: 0.5s all;
}
.btndkdk:active,
.btndkdk:hover {
  background-color: #e2e2f9;
}
.Grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
}
.scrollabel {
  max-height: 280px;
  overflow-y: auto;
}
.scrollabel::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  /* display: none; */
}

.scrollabel::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.scrollabel::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.scrollabel::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}
</style>
